<template>
    <v-card tile elevation="4" class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="purple--text">{{label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="purple" :to="{ name: 'account-create-domain', params: { accountId: this.$route.params.accountId } }">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn>
            <!-- <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn icon color="purple" v-on="on">
                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                    </v-btn>
                    < ! - - <v-tab v-on="on" class="purple--text">
                        <Avatar :attr="mainProfile" :size="36"/>
                        <font-awesome-icon icon="caret-down" class="pl-2 purple--text" fixed-width size="1x"/>
                    </v-tab> - - >
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item-group>
                    <v-list-item :to="{ name: 'service-admin-domain-settings' }">
                        < ! - - <v-list-item-content> - - >
                            <v-list-item-title>Settings</v-list-item-title>
                        < ! - - </v-list-item-content> - - >
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu> -->
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No domains yet.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <v-list-item-content class="text-start">
                    <DomainListItem :attr="item" :link="!selectOne" @created="onCreatedDomain(e)"></DomainListItem>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <!-- <v-row justify="center" class="py-5" v-show="displayCreateDomain">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <v-card elevation="4" class="px-10 pb-5 pt-8">
                <v-form v-model="createDomainForm" ref="createDomainFormRef" @submit="validateCreateDomain" onSubmit="return false;" @keyup.enter.native="validateCreateDomain">
                    <div v-if="add">
                        <v-select :items="addDomainChoices" v-model="addDomainId" label="Select an domain"></v-select>
                    </div>
                    <div v-if="create">
                        <v-text-field
                            v-model=name
                            label="Name"
                            :rules="nameRules"
                            validate-on-blur
                            color="purple"
                            required
                            hint="What should we call you?"
                            autofocus
                            outlined
                        >
                                            <template v-slot:prepend>
                        <font-awesome-icon icon="domain" fixed-width class="mt-1"/>
                    </template>
                        </v-text-field>
                        <v-text-field
                            ref="nameInputRef"
                            v-model=newDomainName
                            label="Display Name"
                            :rules="newDomainNameRules"
                            validate-on-blur
                            color="purple"
                            required
                            hint="The name for the domain"
                            type="text"
                            outlined
                        >
                        </v-text-field>

                    </div>
                    <v-row justify="center">
                        <v-card-actions>
                            <v-btn elevation="4" class="purple white--text" @click="addDomain" :disabled="!createDomainForm" v-if="add">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Add</span>
                            </v-btn>
                            <v-btn elevation="4" class="purple white--text" @click="createDomain" :disabled="!createDomainForm" v-if="create">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Create</span>
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-form>
            </v-card>
            </v-col>
        </v-row> -->
        <CreateDomainDialog v-model="createDomainDialogVisible" @cancel="createDomainDialogVisible = false" @created="createDomainDialogVisible = false"/>
    </v-card>
</template>

<script>
import DomainListItem from '@/components/list-item/DomainListItem.vue';
import CreateDomainDialog from '@/components/account-dashboard/CreateDomainDialog.vue';
import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        DomainListItem,
        CreateDomainDialog,
    },

    props: {
    },

    data: () => ({
        // TODO: eliminate these temporary flags and usage in the template; maybe create a different component for "select a domain"
        selectOne: false,
        add: false,
        create: true,

        list: [],
        createDomainDialogVisible: false,
        // create domain
        displayCreateDomain: false,
        createDomainForm: null,
        newDomainName: null,
        newDomainNameRules: [
            (v) => !!v || 'Display name is required',
            (v) => !v || isValidName(compact(v)) || 'Domain name is required',
        ],
        // add domain
        addDomainChoices: [],
        addDomainId: null,
    }),

    computed: {
        id() {
            return this.policyId;
        },
        label() {
            if (this.selectOne) {
                return 'Select an domain';
            }
            switch (this.list.length) {
            case 0:
                return 'No domains';
            case 1:
                return '1 domain';
            default:
                return `${this.list.length} domains`;
            }
        },
    },

    watch: {
        displayCreateDomain(value) {
            if (value && (this.add || this.create)) {
                this.initAddDomainChoices();
            }
        },
    },

    methods: {
        async loadDomainList() {
            try {
                this.$store.commit('loading', { loadDomainList: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.list();
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadDomainList failed', err);
            } finally {
                this.$store.commit('loading', { loadDomainList: false });
            }
        },
        onClickItem(domainId) {
            if (this.selectOne) {
                this.$emit('selected', { domainId });
            }
        },
        // openCreateDomainDialog() {
        //     this.createDomainDialogVisible = true;
        // },
        // async createDomain() {
        //     this.error = false;
        //     console.log('createDomain');
        //     const request = {
        //         // name: this.name,
        //         name: this.newDomainName,
        //         // agreeToTerms: this.isAgreeToTermsChecked,
        //         // interactionId: this.interactionId, // will be present if domain arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the domain to an appropriate location after domain is created
        //     };
        //     const response = await this.$client.account(this.$route.params.accountId).domain.create(request);
        //     console.log('createDomain response: %o', response);
        //     const { isCreated, id, error } = response;
        //     if (isCreated) {
        //         const domain = { id, ...request };
        //         this.list.push(domain);
        //         this.$emit('created-domain', domain);
        //         this.$emit('added-domain', domain);
        //         this.displayCreateDomain = false;
        //         this.newDomainName = null;
        //     } else if (error) {
        //         this.error = error;
        //     } else {
        //         this.error = 'Request failed';
        //     }
        // },
        // validateCreateDomain() {
        //     if (this.$refs.createDomainFormRef.validate()) {
        //         this.createDomain();
        //     }
        // },

        // async addDomain() {
        //     const domain = { id: this.addDomainId, label: this.addDomainChoices.find((item) => item.value === this.addDomainId).text };
        //     this.list.push(domain);
        //     this.$emit('added-domain', domain);
        //     this.displayCreateDomain = false;
        //     this.addDomainId = null;
        // },
        // async initAddDomainChoices() {
        //     // create a map of which domain ids are already in the list
        //     const domainMap = {};
        //     this.list.forEach((item) => {
        //         domainMap[item.id] = true;
        //     });
        //     // when we show the add/create domain dialog, load the list of available domains to add to the policy (list of all domains less the ones already in the policy)
        //     const result = await this.$client.account(this.$route.params.accountId).domain.list();
        //     console.log(`DomainViewList.vue fetch domains result: ${JSON.stringify(result)}`);
        //     if (result && result.list) {
        //         // filter the results to remove domains already in the policy, then convert the available domains to a choice list for the v-select [ { text, value }, ... ]
        //         this.addDomainChoices = result.list.filter((item) => !domainMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
        //     }
        // },
        onCreatedDomain(info) {
            console.log(`onCreatedDomain ${JSON.stringify(info)}`);
        },
    },

    mounted() {
        this.loadDomainList();
    },
};
</script>
