<template>
    <div>
        <template v-if="link">
            <span><router-link :to="{ name: 'account-verify-domain', params: { accountId: this.$route.params.accountId, domain: name }}">{{ name }}</router-link></span>
        </template>
        <template v-if="!link">
            <span>{{ name }}</span>
        </template>
        <p class="text-caption">{{ status }}</p>
    </div>
</template>

<script>
export default {

    props: {
        // whether the item should link to the account record; a parent component may set this to false if it just wants the user to select an account (and not navigate to it)
        link: {
            type: Boolean,
            default: false,
        },
        attr: {
            type: Object,
        },
    },

    computed: {
        name() {
            return this.attr.domain;
        },
        status() {
            return this.attr.status;
        },
    },

};
</script>
